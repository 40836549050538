<template>
  <div class="winlose-lists">
    <div>
      <WinloseFilter
        :date-start.sync="dateStart"
        :date-end.sync="dateEnd"
        :time-start.sync="timeStart"
        :time-end.sync="timeEnd"
        :selected-game.sync="selectedGame"
        :selected-game-checkboxs.sync="selectedGameCheckboxs"
        :passed-query.sync="passedQuery"
        :search-username.sync="searchUsername"
        :is-search-username="false"
        @onSearch="onSearch"
      />
    </div>
    <div>
      <ReportPlayerOutstandingListsComponent
        ref="reportListsRef"
        :items="items"
        :summary="summary"
        :total-records="totalRecords"
        :upline-type.sync="uplineType"
        :is-simple="false"
        :ref-key-text="refKeyText"
        @onLoadData="onLoadData"
      />
    </div>
  </div>
</template>

<script>
import ReportPlayerOutstandingListsComponent from '@/components/ReportPlayerOutstandingListsComponent.vue'
import WinloseFilter from '@/views/reports/WinloseFilter.vue'
import dayjs from '@/libs/dayjs'
import { GameLists } from '@/utils/report'
import store from '@/store'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  components: {
    ReportPlayerOutstandingListsComponent,
    WinloseFilter,
  },
  props: {

  },
  data() {
    return {

      isSimple: false,

      // diff
      refKeyText: "Member",
      title: 'Player Outstanding',
      urlPath: '/reports/v2/player_outstanding_lists',
      routeName: 'reports-player-outstanding-lists',

      items: [
      ],
      totalRecords: 0,
      summary: {},
      uplineType: '',

      // search
      dateStart: dayjs().format('YYYY-MM-DD'),
      dateEnd: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      timeStart: '00:00',
      timeEnd: '00:00',
      selectedGame: '',
      selectedGameCheckboxs: [],
      searchUsername: '',
      passedQuery: false,

      allowQueryKeys: [
        "dateStart",
        "dateEnd",
        "timeStart",
        "timeEnd",
        "selectedGame",
        "selectedGameCheckboxs",
        "searchUsername",
        "isSimple",

        // pagination
        "page",
        "perPage",
        "columnFilters",
        "sort",
      ],

      groupId: '',

    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
  },
  watch: {
    $route(to, from) {
      // if (['reports-member-winlose-lists', 'reports-player-winlose-lists'].some(list => list === to.name)) {
      if (this.$route.name === to.name) {
        let groupId
        if ('groupId' in this.$route.params && this.$route.params.groupId) {
          groupId = this.$route.params.groupId
        }

        // this.groupId = groupId || ''
        // if (!groupId) {
        //   store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
        // }

        this.setRouteParamAndQuery(to)
        if (!this.groupId) {
          store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')
        }

        if (!this.groupId || !this.searchUsername) {
          this.$router.push({
            // name: this.$route.name,
            name: 'reports-player-outstanding-lists',
            params: {
              groupId,
            },
            query: {
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              timeStart: this.timeStart,
              timeEnd: this.timeEnd,
              selectedGame: this.selectedGame,
              selectedGameCheckboxs: this.selectedGameCheckboxs,
              searchUsername: '',
              isSimple: this.isSimple,
              // t: Date.now(),

              page: this.page,
              perPage: this.perPage,
              columnFilters: this.columnFilters,
              sort: this.sort,
            },

          }).catch(error => {
            if (error.name !== "NavigationDuplicated") {
              throw error;
            }
          })
        }
      }
    },
  },
  mounted() {
    this.uplineType = this.GetAgentGroupType(this.userData)

    this.setRouteParamAndQuery(this.$route)

    this.onSearch()
  },
  beforeDestroy() {
    this.$wait.end('reports-loading')
  },
  methods: {
    GetAgentGroupType,
    setRouteParamAndQuery(route) {
      // query params
      if ('groupId' in route.params && route.params.groupId) {
        this.groupId = route.params.groupId
      } else {
        this.groupId = ''
      }

      const queryKeys = Object.keys(route.query)
      if (Array.isArray(queryKeys) && Array.from(queryKeys).length > 0) {
        for (let index = 0; index < queryKeys.length; index += 1) {
          const key = queryKeys[index];
          if (this.allowQueryKeys.some(list => list === key)) {
            if (!this.passedQuery) {
              this.passedQuery = true
            }

            let value = route.query[key]
            if (key === "selectedGameCheckboxs") {
              value = Array.isArray(value) ? Array.from(value) : [value]
            } else if (key === 'isSimple') {
              value = Boolean(String(value).toUpperCase() === "TRUE")
            }

            this[key] = value
          }
        }
      }

      // if (route.query.isSimple) {
      //   this.isSimple = true
      // }
      // lists always details
      // this.isSimple = false

      if (!this.passedQuery || this.selectedGameCheckboxs.length === 0) {
        this.selectedGame = 'All'
        this.selectedGameCheckboxs = GameLists.map(list => list.value)
      }
    },
    onLoadData() {
      this.onSearch()
    },
    async onSearch() {
      this.$wait.start('reports-loading')

      const tableParams = this.$refs.reportListsRef.getTableDataParams()

      const routeData = {
        // diff
        name: this.routeName,
        params: {
          ...this.groupId && {
            groupId: this.groupId,
          },
        },
        query: {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          timeStart: this.timeStart,
          timeEnd: this.timeEnd,
          selectedGame: this.selectedGame,
          selectedGameCheckboxs: this.selectedGameCheckboxs,
          searchUsername: this.searchUsername,
          isSimple: this.isSimple,
          // t: Date.now(),
          ...tableParams,
        },
      }
      this.$router.push(routeData).catch(error => {
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      })

      try {
        // diff url path
        let pathWithParam = `${this.urlPath}`
        if (this.groupId) {
          pathWithParam += `/${this.groupId}`
        }
        const { data } = await this.$http.post(pathWithParam, {
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          timeStart: this.timeStart,
          timeEnd: this.timeEnd,
          selectedGame: this.selectedGame,
          selectedGameCheckboxs: this.selectedGameCheckboxs,
          searchUsername: this.searchUsername,
          ...tableParams,
        })

        this.items = data?.data?.docs ?? []
        this.summary = data?.data?.summary ?? {}
        this.totalRecords = data?.data?.totalDocs ?? 0

        const responseSuccess = data?.success
        const responseMessage = data?.status?.message
        if (!responseSuccess) {
          this.$swal({
            icon: 'error',
            title: this.$t(responseMessage || 'Error'),
          })
          return
        }

        if (this.refKeyText !== 'Match') {
          // breadcrumb
          store.commit('appBreadCrumb/REMOVE_BREADCRUMBS_CUSTOM')

          const { uplinesGroup } = data.data
          if (uplinesGroup && Array.isArray(uplinesGroup) && Array.from(uplinesGroup).length > 0) {
            const queryAll = routeData?.query ?? {}
            for (let index = 0; index < uplinesGroup.length; index += 1) {
              const uplineGroup = uplinesGroup[index]

              const routeDataMaybeWithNewGroup = {
                ...routeData,
                // name: this.$route.name,
                name: String(this.$route.name).replace('-lists', this.isSimple ? '-simple' : '-detail'),
                params: {
                  ...routeData.params,
                  groupId: uplineGroup.id,
                },
                query: {
                  ...routeData.query,
                  searchUsername: '',
                },
              }

              store.commit('appBreadCrumb/ADD_BREADCRUMBS', {
                text: uplineGroup.username,
                custom: true,
                to: { ...routeDataMaybeWithNewGroup },
                active: false,
              })
            }
            store.commit('appBreadCrumb/REPLACE_ROOT_QUERY_BREADCRUMBS', queryAll)
          }

          // member
          store.commit('appBreadCrumb/ADD_BREADCRUMBS', {
            text: data.data.group.username,
            custom: true,
            active: true,
          })
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message || 'Error'),
        })
      } finally {
        this.$wait.end('reports-loading')
      }
    },
  },
}
</script>
