<template>
  <b-card-code class="">
    <div class="d-flex justify-content-end align-items-center">
      <div>
        <VueGoodTableSwitchCompactMode />
      </div>
    </div>

    <VueGoodTableRemote
      ref="tableData"
      :total-records="totalRecords"
      :rows="items"
      :columns="columns"
      :columns-top="columnsTop"
      :is-line-number="true"
      :is-loading="$wait.is('reports-loading')"
      @onLoadData="onLoadData"
    >
      <template slot="table-actions-bottom2">
        <tr class="tr-on-hover">
          <td
            colspan="3"
            class="text-center black--text text-total"
          >
            <span>{{ $t('Summary') }}</span>
          </td>
          <td
            class="text-right text-total black--text"
          >
            {{ DecimalFormat(summary.amount) }}
          </td>
          <td
            class="text-right text-total black--text"
          >
            {{ DecimalFormat(summary.validAmount) }}
          </td>

          <!-- member -->
          <td
            class="text-right text-total"
            :class="[Number(summary.memberWinLose) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormat(summary.memberWinLose) }}
          </td>
          <!-- end member -->
        </tr>
      </template>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="FieldNumberLists.some(list => list === props.column.field)">
          <span
            class="text-total"
            :class="[Number(props.formattedRow[props.column.field]) < 0 ? 'red--text' : 'black--text']"
          >
            {{ DecimalFormatNumberByField(props.formattedRow[props.column.field], props.column.field) }}
          </span>
        </span>

        <!-- userDetail -->
        <div v-else-if="props.column.field === 'userDetail'">
          <div class="black--text font-weight-bold">
            <span class="badge badge-light-primary pl-0 pr-0">{{ props.row.username_lower }}</span>
          </div>
          <div class="black--text font-weight-bold">
            เครดิตก่อน : {{ DecimalFormat(props.row.creditBefore) }}
          </div>
          <div class="black--text font-weight-bold">
            เครดิตหลัง : {{ DecimalFormat(props.row.creditAfter) }}
          </div>
          <div class="black--text font-weight-bold">
            {{ DatetimeFormat(props.row.game_start_date) }}
          </div>
          <div class="black--text font-weight-bold">
            {{ props.row.user_ip }}
          </div>
        </div>
        <!-- end userDetail -->

        <!-- betdetail -->
        <div
          v-else-if="props.column.field === 'betdetail'"
          class=""
        >
          <div class="black--text font-weight-bold">
            <span class="badge badge-light-primary pl-0 pr-0">ค่าย : {{ String(props.row.source).toUpperCase() }}</span>
          </div>
          <div class="black--text font-weight-bold">
            เกมส์ : {{ props.row.game.game }}
          </div>
          <div class="black--text font-weight-bold">
            ประเภท : {{ props.row.transaction_type }}
          </div>
          <div class="black--text font-weight-bold">
            <!-- อ้างอิง : {{ props.row.game.ref }} -->
            อ้างอิง : <a
              href="javascript;"
              @click.prevent="onClickReportDetail(props.row)"
            >{{ props.row.game.ref }}</a>
          </div>
          <div
            v-if="props.row.game.transaction_ref"
            class="black--text font-weight-bold"
          >
            Note : {{ props.row.game.transaction_ref }}
          </div>
        </div>
        <!-- end betdetail -->

        <!-- betShare -->
        <div
          v-else-if="props.column.field === 'betShare'"
          class=""
        >
          <div
            v-if="!IsUpline({uplineType, currentType: 'API'})"
            class="black--text font-weight-bold"
          >
            Api : {{ get(props.row, 'commission.apiSetting.shareReceive', get(props.row, 'commission.apiSetting.upline', 0)) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'COMPANY'})"
            class="black--text font-weight-bold"
          >
            Company : {{ get(props.row, 'commission.companySetting.shareReceive', get(props.row, 'commission.companySetting.upline', 0)) }}
          </div>

          <div
            v-if="!IsUpline({uplineType, currentType: 'PARTNER'})"
            class="black--text font-weight-bold"
          >
            Partner : {{ get(props.row, 'commission.partnerSetting.shareReceive', get(props.row, 'commission.partnerSetting.upline', 0)) }}
          </div>

          <div
            v-if="!IsUpline({uplineType, currentType: 'SENIOR'})"
            class="black--text font-weight-bold"
          >
            Senior : {{ get(props.row, 'commission.seniorSetting.shareReceive', get(props.row, 'commission.seniorSetting.upline', 0)) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'MASTER'})"
            class="black--text font-weight-bold"
          >
            Master : {{ get(props.row, 'commission.masterSetting.shareReceive', get(props.row, 'commission.masterSetting.upline', 0)) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'AGENT'})"
            class="black--text font-weight-bold"
          >
            Agent : {{ get(props.row, 'commission.agentSetting.shareReceive', get(props.row, 'commission.agentSetting.upline', 0)) }}
          </div>
        </div>
        <!-- end betShare -->

        <!-- betCommission -->
        <div
          v-else-if="props.column.field === 'betCommission'"
          class=""
        >
          <div
            v-if="!IsUpline({uplineType, currentType: 'API'})"
            class="black--text font-weight-bold"
          >
            Api : {{ get(props.row, 'commission.apiSetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'COMPANY'})"
            class="black--text font-weight-bold"
          >
            Company : {{ get(props.row, 'commission.companySetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'PARTNER'})"
            class="black--text font-weight-bold"
          >
            Partner : {{ get(props.row, 'commission.partnerSetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'SENIOR'})"
            class="black--text font-weight-bold"
          >
            Senior : {{ get(props.row, 'commission.seniorSetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'MASTER'})"
            class="black--text font-weight-bold"
          >
            Master : {{ get(props.row, 'commission.masterSetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'AGENT'})"
            class="black--text font-weight-bold"
          >
            Agent : {{ get(props.row, 'commission.agentSetting.commission', 0) }}
          </div>
          <div
            v-if="!IsUpline({uplineType, currentType: 'AGENT'})"
            class="black--text font-weight-bold"
          >
            Member : {{ get(props.row, 'commission.memberCommission', 0) }}
          </div>
        </div>
        <!-- end betCommission -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template slot="loadingContent">
        <div
          class="spinner-border text-primary"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </template>
    </VueGoodTableRemote>

    <ModalReportDetailBaccarat
      :show.sync="showModalReportDetailBaccarat"
      :report="reportDataBaccarat"
      :report-item="reportItem"
    />
    <ModalReportDetailSicbo
      :show.sync="showModalReportDetailSicbo"
      :report="reportDataSicbo"
      :report-item="reportItem"
    />
    <ModalReportDetailRoulette
      :show.sync="showModalReportDetailRoulette"
      :report="reportDataRoulette"
      :report-item="reportItem"
    />
    <ModalReportDetailDragonTiger
      :show.sync="showModalReportDetailDragonTiger"
      :report="reportDataDragonTiger"
      :report-item="reportItem"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table-custom'
import { AgentTypes, IsReportUpline, IsUpline } from '@/utils/agent'
import { DecimalFormat, DecimalFormatCustomFix } from '@/utils/number'
import { DatetimeFormat } from '@/utils/date-format'
import { get } from 'lodash'
import VueGoodTableRemote from '@/components/VueGoodTableRemote'
import ModalReportDetailBaccarat from '@/components/ModalReportDetailBaccarat'
import ModalReportDetailSicbo from '@/components/ModalReportDetailSicbo'

import ModalReportDetailRoulette from '@/components/ModalReportDetailRoulette'
import ModalReportDetailDragonTiger from '@/components/ModalReportDetailDragonTiger'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'

export default {
  components: {
    VueGoodTableSwitchCompactMode,
    ModalReportDetailBaccarat,
    ModalReportDetailSicbo,
    ModalReportDetailRoulette,
    ModalReportDetailDragonTiger,
    VueGoodTableRemote,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCardCode,
    BButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    summary: {
      type: Object,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: true,
    },
    uplineType: {
      type: String,
      required: true,
    },
    isSimple: { type: Boolean, required: true },
    refKeyText: { type: String, required: true },
  },
  data() {
    return {

      showModalReportDetailBaccarat: false,
      reportDataBaccarat: null,

      showModalReportDetailSicbo: false,
      reportDataSicbo: null,

      showModalReportDetailRoulette: false,
      reportDataRoulette: null,

      showModalReportDetailDragonTiger: false,
      reportDataDragonTiger: null,

      // all
      reportItem: {},
    }
  },
  computed: {
    bgTotalCom() {
      return 'table__tdclass__lists'
    },
    columnsTop() {
      const headers = [
        {
          colspan: 4,
        },
        {
          label: this.$t('Member'),
          colspan: 1,
          style: {
            'text-align': 'center',
          },
        },
      ]
      return headers
    },
    columns() {
      const headers = [
        {
          label: 'Bet Infomation',
          field: 'userDetail',
          width: '200px',
          tdClass: 'detail-td-class',
          thClass: 'detail-th-class',
          sortable: false,
        },
        {
          label: 'Bet Detail',
          field: 'betdetail',
          width: '250px',
          tdClass: 'detail-td-class',
          thClass: 'detail-th-class',
          sortable: false,
        },
        {
          label: 'Amount',
          field: 'amount',
          type: 'number',
          tdClass: 'detail-td-class',
          thClass: 'detail-th-class',
          width: '120px',
        },
        {
          label: 'ValidAmount',
          field: 'validAmount',
          type: 'number',
          tdClass: 'detail-td-class',
          thClass: 'detail-th-class',
          width: '120px',
        },
        {
          label: 'W/L',
          field: 'memberWinLose',
          type: 'number',
          tdClass: 'detail-td-class',
          thClass: 'detail-th-class',
          width: '120px',
        },
      ]

      return headers
    },
    FieldNumberLists() {
      return [
        "memberWinLose",
        "amount",
        "validAmount",
      ]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
  },
  methods: {
    IsReportUpline,
    IsUpline,
    DecimalFormat,
    DecimalFormatCustomFix,
    DatetimeFormat,
    get,
    DecimalFormatNumberByField(total, field) {
      if (Number.isNaN(Number(total)) || total === '0.00') {
        return '0.00'
      }

      // if (String(field).endsWith('WinLoseCom')) {
      //   return total
      // }

      // return this.DecimalFormatCustomFix(total, 2)
      return total
    },
    onViewLists(row) {
      return {
        name: 'reports-player-outstanding-lists',
        params: {
          // eslint-disable-next-line no-underscore-dangle
          member: row._id,
        },
        query: this.$route.query,
      }
    },
    onLoadData() {
      this.$emit('onLoadData')
    },
    getTableDataParams() {
      const params = this.$refs.tableData.getParams()
      return params
    },
    async onClickReportDetail(reportItem) {
      this.reportItem = reportItem
      const reportItemId = reportItem?._id
      const reportItemSource = reportItem?.source
      const reportItemGameId = reportItem?.game?.game

      if (this.$wait.is(`report-detail-loading-${reportItemId}`)) {
        return
      }

      this.$wait.start(`report-detail-loading-${reportItemId}`)
      try {
        const { data } = await this.$http.post(`/report-detail/get-outstanding`, {
          id: reportItemId,
        })
        if (data?.success) {
          if (String(reportItemSource).toLowerCase() === 'casino.sa') {
            switch (String(reportItemGameId).toLowerCase()) {
              case 'bac':
                this.showModalReportDetailBaccarat = true
                this.reportDataBaccarat = data?.data
                break;
              case 'dtx':
                this.showModalReportDetailDragonTiger = true
                this.reportDataDragonTiger = data?.data
                break;
              case 'sicbo':
                this.showModalReportDetailSicbo = true
                this.reportDataSicbo = data?.data
                break;
              case 'rot':
                this.showModalReportDetailRoulette = true
                this.reportDataRoulette = data?.data
                break;
              default:
                this.modalSagameBac = false
                this.modalSagameDtx = false
                this.modalSagameSicbo = false
                this.modalSagameRot = false
                break
            }
          } else {
            const historyurl = data?.data?.url
            if (historyurl) {
              let defaultSlotWidth = 500
              let defaultSlotHeight = 800

              const defaultOtherWidth = 1200
              const defaultOtherHeight = 700
              if (
                String(reportItemSource).startsWith('slot.kagaming')
              || String(reportItemSource).startsWith('slot.aegaming')
              ) {
                defaultSlotWidth = 0
                defaultSlotHeight = 0
                this.PopupCenter(historyurl, defaultSlotWidth, defaultSlotHeight)
              } else if (String(reportItemSource).startsWith('slot.')) {
                this.PopupCenter(historyurl, defaultSlotWidth, defaultSlotHeight)
              } else {
                this.PopupCenter(historyurl, defaultOtherWidth, defaultOtherHeight)
              }
            } else {
              this.$swal({
                icon: 'error',
                title: this.$t(data?.status?.message ?? 'Error'),
              })
            }
          }
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data?.status?.message ?? 'Error'),
          })
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message || 'Error'),
        })
      } finally {
        this.$wait.end(`report-detail-loading-${reportItemId}`)
      }
    },
    PopupCenter(url, w, h) {
      const y = window.outerHeight / 2 + window.screenY - (h / 2)
      const x = window.outerWidth / 2 + window.screenX - (w / 2)
      window.open(url, 'parlay', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);

      // const left = window.screen.width / 2 - w / 2
      // const top = window.screen.height / 2 - h / 2
      // window.open(
      //   url,
      //   "_blank",
      //   `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${
      //     w
      //   }, height=${
      //     h
      //   }, top=${
      //     top
      //   }, left=${
      //     left}`,
      // );
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
// @import '@/assets/scss/table-list.scss';
</style>
